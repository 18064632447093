import isEqualWith from 'lodash/isEqualWith';
import uniqBy from 'lodash/uniqBy';

import { Customer } from '@/entity/account/customer/Customer';
import { Gateway } from '@/entity/basic/Gateway';
import { Money } from '@/entity/basic/Money';
import { JourneyOfferDetails } from '@/entity/basic/offer-details/JourneyOfferDetails';
import { type BookingProgress } from '@/entity/booking-progress/BookingProgress';
import { OfferStatus } from '@/entity/common-constants';
import { type PriceOption } from '@/entity/events/task/PriceOption';
import { fromJsonArray, fromJsonArrayWith } from '@/entity/index';
import { CustomerDTO } from '@/entity/journey/CustomerDTO';
import { PriceSummary } from '@/entity/journey/PriceSummary';
import { BookingRoute } from '@/entity/journey/route/BookingRoute';
import { BookingStop } from '@/entity/journey/stop/BookingStop';
import { BusRouteStatus } from '@/entity/search-results/BookingSearchResult';
import { type IJourney } from '@/features/journey-planning';
import { FULL_BREAK_MINUTES } from '@/utils/constants';

export class BookingJourney {
    public token: string;

    public applicableSchool: boolean;

    public onlyOwnBuses: boolean;

    public companyProvidesDriverRooms: boolean;

    public makePTVRequest: boolean;

    public routes: BookingRoute[];

    public bookingProgress: BookingProgress;

    public considerSplit: boolean;

    public customerDTO?: CustomerDTO;

    public paymentGateways: Gateway[];

    public priceSummary: PriceSummary;

    public earliestStop: BookingStop;

    public offerDetails?: JourneyOfferDetails;

    public amendBookingToken?: string;

    public amendBookingPriceSummary?: PriceSummary;

    public name?: string;

    public reseller?: Customer;

    public driverRoomsNeeded: boolean;

    public couponCode?: string;

    public ratioCustomerId?: number;

    public ratioTransferNumber?: string;

    constructor(json: Record<string, any>) {
        this.token = json.token;
        this.applicableSchool = json.applicableSchool;
        this.onlyOwnBuses = json.onlyOwnBuses;
        this.companyProvidesDriverRooms = json.companyProvidesDriverRooms;
        this.makePTVRequest = json.makePTVRequest ?? false;
        this.considerSplit = json.considerSplit;
        this.routes = fromJsonArray(BookingRoute, json.routes);
        this.bookingProgress = json.bookingProgress;
        this.customerDTO = json.customerDTO ? new CustomerDTO(json.customerDTO) : undefined;
        this.paymentGateways = fromJsonArrayWith(Gateway.fromJson, json.paymentGateways);
        this.priceSummary = new PriceSummary(json.priceSummary);
        this.earliestStop = new BookingStop(json.earliestStop);
        this.offerDetails = json.offerDetails ? new JourneyOfferDetails(json.offerDetails) : undefined;
        this.amendBookingToken = json.amendBookingToken;
        this.amendBookingPriceSummary = json.amendBookingPriceSummary
            ? new PriceSummary(json.amendBookingPriceSummary)
            : undefined;
        this.name = json.name ?? undefined;
        this.reseller = json.reseller ? new Customer(json.reseller) : undefined;
        this.driverRoomsNeeded = json.driverRoomsNeeded;
        this.couponCode = json.couponCode;
        this.ratioCustomerId = json.ratioCustomerId;
        this.ratioTransferNumber = json.ratioTransferNumber;
    }

    public toString(): string {
        return this.token;
    }

    public getFormValues(): IJourney {
        return {
            companyProvidesDriverRooms: false,
            applicableSchool: this.applicableSchool,
            onlyOwnBuses: this.onlyOwnBuses,
            makePTVRequest: this.makePTVRequest,
            token: this.token,
            routes: this.routes.map(route => ({
                uuid: route.uuid,
                pax: route.pax,
                considerSplit: this.considerSplit,
                confirmSplit: false,
                // TODO: For what is that?
                // group: route.group,
                stops: route.stops.map((stop, i, stops) => ({
                    uuid: stop.uuid,
                    // First stop has no arrival time
                    arrivalDateTime: i === 0 ? null : stop.arrivalDateTime,
                    // Last stop has no departure time (this is needed to add break time to the trip company intermediate when the user clicks on Rückfahrt)
                    departureDateTime: i === stops.length - 1 ? null : stop.departureDateTime,
                    location: stop.location,
                    localBus: stop.localBus ?? null,
                    tripCompany: stop.tripCompany ? stop.tripCompany.getFormValues() : null,
                })),
            })),
            name: this.name,
        };
    }

    public getRoute(uuid?: string): BookingRoute {
        return this.routes.find(route => route.uuid === uuid) ?? this.routes[0];
    }

    public busCount(): number {
        return this.routes.reduce((sum, current) => sum + current.getSelectedSearchResults().length, 0);
    }

    public isAcceptedOffer(): boolean {
        return this.offerDetails?.offerStatus === OfferStatus.ACCEPTED;
    }

    public isDeletedOffer(): boolean {
        return this.offerDetails?.offerStatus === OfferStatus.DELETED;
    }

    public getUniqueCompanies() {
        return uniqBy(
            this.routes.flatMap(route => route.getSelectedSearchResults().map(result => result.bus.company)),
            'id',
        );
    }

    public isAmendBookingAndBusNotAvailable() {
        return (
            this.amendBookingToken != null && this.routes[0].searchResults[0].status === BusRouteStatus.NOT_AVAILABLE
        );
    }

    public getPriceDifferenceBetweenAmendAndCurrentJourney(): Money | null {
        const previousPrice = this.amendBookingPriceSummary?.total[0];
        const currentPrice = this.priceSummary.total[0];

        if (!this.amendBookingToken || !previousPrice) return null;

        return new Money(getDifference(previousPrice.amount, currentPrice.amount), currentPrice.currency);
    }

    // If there are more than one route with the same group number, it means that the route is split
    public hasAutoSplitRoutes() {
        return new Set(this.routes.map(r => r.group)).size !== this.routes.length;
    }

    public hasLocalBusAndSplitRoute() {
        return this.routes.some(r => r.stops.some(s => s.hasLocalBusAndSplitRoute()));
    }

    public hasExclusivelyInvalidPriceOptionStatus() {
        return this.routes
            .flatMap(r => r.searchResults.map(s => s.status))
            .filter(s => s !== BusRouteStatus.OK)
            .every(s => s === BusRouteStatus.INVALID_PRICE_OPTION);
    }

    public hasInvalidStatus() {
        return this.routes.some(r => r.searchResults.some(s => s.hasInvalidStatus()));
    }

    public hasInvalidStatusInSelection() {
        return this.routes.some(r => r.getSelectedSearchResults().some(s => s.hasInvalidStatus()));
    }

    public driverRoomsNeededConsideringSplitRoutes() {
        return this.routes.some(r =>
            r.stops.some(
                s => s.stayDuration.as('minutes') >= FULL_BREAK_MINUTES && !s.statuses.includes('SPLIT_ROUTE'),
            ),
        );
    }

    public isPossible(): boolean {
        return this.routes.every(route => route.isPossible());
    }

    public isReturnJourneyButNoBreakTime() {
        return this.routes.some(r => {
            const firstStop = r.stops[0];
            const lastStop = r.stops[r.stops.length - 1];

            const isReturn = lastStop.location.equals(firstStop.location);
            const hasNoBreakTime = r.stops.every(s => s.stayDuration.as('minutes') < 1);

            return isReturn && hasNoBreakTime;
        });
    }

    public isNotReturnJourney() {
        return this.routes.some(r => {
            const firstStop = r.stops[0];
            const lastStop = r.stops[r.stops.length - 1];

            return !lastStop.location.equals(firstStop.location);
        });
    }

    public hasNoRefund(companyId: number): boolean {
        return this.routes.some(r =>
            r.getSelectedSearchResults().some(s => s.bus.company.id === companyId && s.selectedPriceOption?.noRefund),
        );
    }

    public allResultsHaveSamePriceOptions() {
        const firstSearchResult = this.routes[0].searchResults[0];

        const comparator = (a: PriceOption, b: PriceOption) => a.id === b.id;

        const allSamePriceOptions = this.routes.every(route =>
            route.searchResults.every(searchResult =>
                isEqualWith(searchResult.priceOptions, firstSearchResult.priceOptions, comparator),
            ),
        );

        return allSamePriceOptions;
    }
}

function getDifference(a: number, b: number) {
    if (a > b) {
        return a - b;
    }

    return a + b;
}
