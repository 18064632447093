import { Entity } from '@/entity/A_Entity';
import { DistanceAndDuration } from '@/entity/basic/DistanceAndDuration';
import { fromJsonArray } from '@/entity/index';
import { DefaultStop } from '@/entity/journey/saved/DefaultStop';

export class DefaultRoute extends Entity {
    public uuid: string;

    public pax: number;

    public calculatedTravel: DistanceAndDuration;

    public calculatedTravelWithBreaks: DistanceAndDuration;

    public stops: DefaultStop[];

    public selected: boolean;

    public considerSplit: boolean;

    public makePTVRequest?: boolean;

    constructor(json: Record<string, any>) {
        super(json);
        this.uuid = json.uuid;
        this.pax = json.pax;
        this.calculatedTravel = DistanceAndDuration.fromJson(json.calculatedTravel);
        this.calculatedTravelWithBreaks = DistanceAndDuration.fromJson(json.calculatedTravelWithBreaks);
        this.stops = fromJsonArray(DefaultStop, json.stops);
        this.selected = json.selected;
        this.considerSplit = json.considerSplit ?? false;
        this.makePTVRequest = json.makePTVRequest;
    }
}
