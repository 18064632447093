import uniqBy from 'lodash/uniqBy';
import { DateTime } from 'luxon';

import { Entity } from '@/entity/A_Entity';
import { Customer } from '@/entity/account/customer/Customer';
import { ContactData } from '@/entity/basic/ContactData';
import { Gateway } from '@/entity/basic/Gateway';
import { BookingOfferDetails } from '@/entity/basic/offer-details/BookingOfferDetails';
import { PaymentLog } from '@/entity/booking/PaymentLog';
import { DetailsBookingRoute } from '@/entity/booking/route/DetailsBookingRoute';
import { type BookingBusCompany } from '@/entity/company/bus/BookingBusCompany';
import { fromJsonArray, fromJsonArrayWith } from '@/entity/index';
import { Integration } from '@/entity/integration/Integration';
import { PriceSummary } from '@/entity/journey/PriceSummary';

export class DetailsBooking extends Entity {
    public token: string;

    public bookedAt: DateTime;

    public amendedAt: DateTime;

    public customerComment: string;

    public customerContactData: ContactData;

    public companyProvidesDriverRooms: boolean;

    public cancelled: boolean;

    public customer?: Customer;

    public priceSummary: PriceSummary;

    public routes: DetailsBookingRoute[];

    public offerDetails?: BookingOfferDetails;

    public reservation: boolean;

    // the DetailsBookingProjection only returns IntegrationProjection (name and url),
    // the other settings are the defaults here
    public bookingIntegration?: Integration;

    public onlinePayment: boolean;

    public paymentPending: boolean;

    public paymentError: boolean;

    public paymentWarning: boolean;

    public latestOnlinePaymentLog?: PaymentLog;

    public paymentGateways: Gateway[];

    public showExtraReviewLabels: boolean;

    public driverRoomsNeeded: boolean;

    public couponCode?: string;

    public isEmileWeberBooking: boolean;

    public organizationPoNumber: string;

    public organizationCostCenter: string;

    constructor(json: Record<string, any>) {
        super(json);
        this.token = json.token;
        this.bookedAt = DateTime.fromISO(json.bookedAt);
        this.amendedAt = DateTime.fromISO(json.amendedAt);
        this.customerComment = json.customerComment;
        this.customerContactData = ContactData.fromJson(json.customerContactData);
        this.companyProvidesDriverRooms = json.companyProvidesDriverRooms;
        this.cancelled = json.cancelled;
        this.customer = json.customer ? new Customer(json.customer) : undefined;
        this.priceSummary = new PriceSummary(json.priceSummary);
        this.routes = fromJsonArray(DetailsBookingRoute, json.routes);
        this.offerDetails = json.offerDetails ? new BookingOfferDetails(json.offerDetails) : undefined;
        this.reservation = json.reservation;
        this.bookingIntegration = json.bookingIntegration ? new Integration(json.bookingIntegration) : undefined;
        this.onlinePayment = json.onlinePayment;
        this.paymentPending = json.paymentPending;
        this.paymentError = json.paymentError;
        this.paymentWarning = json.paymentWarning;
        this.latestOnlinePaymentLog = json.latestOnlinePaymentLog
            ? new PaymentLog(json.latestOnlinePaymentLog)
            : undefined;
        this.paymentGateways = fromJsonArrayWith(Gateway.fromJson, json.paymentGateways);
        this.showExtraReviewLabels = json.showExtraReviewLabels;
        this.driverRoomsNeeded = json.driverRoomsNeeded;
        this.couponCode = json.couponCode;
        this.isEmileWeberBooking = json.emileWeberBooking;
        this.organizationPoNumber = json.organizationPoNumber;
        this.organizationCostCenter = json.organizationCostCenter;
    }

    public getUniqueCompanies() {
        return uniqBy(
            this.routes.flatMap(route => route.tasks.map(task => task.bus.company)),
            'id',
        );
    }

    public getNotCanceledCompanies() {
        return uniqBy(
            this.routes.flatMap(route => route.tasks.filter(task => !task.cancelled).map(task => task.bus.company)),
            'id',
        );
    }

    public getRouteByTaskId(taskId?: number) {
        if (!taskId) return undefined;
        const route = this.routes.find(r => r.tasks.find(task => task.id === taskId));
        return route;
    }

    public getRouteById(routeUuid?: string) {
        return this.routes.find(route => route.uuid === routeUuid) ?? this.routes[0];
    }

    public isCancellable() {
        return (
            !this.cancelled &&
            this.routes.every(route => route.tasks.every(task => task.taskFrom.time > DateTime.now()))
        );
    }

    /**
     * Return all routes that are not cancelled.
     */
    public getNonCancelledRoutes() {
        return this.routes.filter(route => !route.cancelled);
    }

    /**
     * Return the number of buses that have not been cancelled.
     */
    public busCount() {
        return this.getNonCancelledRoutes().reduce((sum, current) => sum + current.tasks.length, 0);
    }

    /**
     * Requirements for amending a booking: 1 non-cancelled route with 1 bus, paid on invoice.
     * These should be the same as the checks in getJourneyForAmendBooking.
     */
    public canAmendBooking() {
        return this.getNonCancelledRoutes().length === 1 && this.busCount() === 1 && !this.onlinePayment;
    }

    public getBookingMode() {
        return this.reservation ? 'reservation' : 'booking';
    }

    public hasNoRefund(companyId: number) {
        const tasksForCompany = this.routes.flatMap(route =>
            route.tasks.filter(task => task.bus.company.id === companyId),
        );

        return tasksForCompany.some(task => task.priceOption?.noRefund);
    }

    // Check if the user chose different cancellation policies for the booking(ex. one bus is refundable, the other is not) for the same company
    public hasDifferentCancellationPolicies(company: BookingBusCompany) {
        const tasksForCompany = this.routes.flatMap(route =>
            route.tasks.filter(task => task.bus.company.id === company.id),
        );
        return (
            tasksForCompany.some(task => task.priceOption && task.priceOption.noRefund) &&
            tasksForCompany.some(task => task.priceOption && !task.priceOption.noRefund)
        );
    }

    public getSingleTask() {
        if (this.routes.length === 0 || this.routes[0].tasks.length === 0) return null;
        return this.routes[0].tasks[0];
    }
}
