import { type TFunction } from 'i18next';

import { CustomerGroupType } from '@/entity/common-constants';

export class CustomerGroup {
    public readonly id: number;

    public readonly name: string;

    public readonly type: CustomerGroupType;

    constructor(json: Record<string, any>) {
        this.id = json.id;
        this.name = json.name;
        this.type = json.type;
    }
}

/**
 * Get a customer group translation either by customerGroupType or by customerGroupId
 */
export const getCustomerGroupTypeTranslation = ({
    customerGroupType,
    customerGroupId,
    t,
}: {
    customerGroupType?: CustomerGroupType;
    customerGroupId?: number;
    t: TFunction;
}) => {
    if (customerGroupType) {
        switch (customerGroupType) {
            case CustomerGroupType.BUSINESS: {
                return t('business');
            }
            case CustomerGroupType.RESELLER: {
                return t('reseller');
            }
            case CustomerGroupType.SCHOOL: {
                return t('school');
            }
            case CustomerGroupType.PRIVATE: {
                return t('private_customer');
            }
            default: {
                return '';
            }
        }
    }

    // TODO: BUF-70: customer group translations
    if (customerGroupId) {
        switch (customerGroupId) {
            case 1: {
                return t('school');
            }
            case 2: {
                return t('reseller');
            }
            case 3: {
                return t('business');
            }
            case 4: {
                return t('private_customer');
            }
            default: {
                return '';
            }
        }
    }

    return '';
};
