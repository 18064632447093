import { type TFunction } from 'i18next';
import Cookies from 'js-cookie';
import ga4 from 'react-ga4';
import { type UaEventOptions } from 'react-ga4/types/ga4';

import { ENV } from '@/config/env';
import { getCustomerGroupTypeTranslation } from '@/entity/customergroup/CustomerGroup';
import { type BookingJourney } from '@/entity/journey/BookingJourney';
import { type IJourney } from '@/features/journey-planning';

export interface GA4Event extends UaEventOptions {
    action:
        | 'search-form'
        | 'step-completed'
        | 'auth'
        | 'operator-offer'
        | 'tour'
        | 'misc'
        | 'angebote_anzeigen'
        | 'trip-company';
}

export const initGA = () => {
    ga4.initialize(ENV.googleAnalyticsId, {
        gaOptions: {
            cookieFlags: 'SameSite=None; Secure',
        },
    });
};

export const hasAcceptedGABefore = () => Object.keys(Cookies.get()).includes('_ga');

export const trackPurchase = (journey: BookingJourney) => {
    const { amount, currency } = journey.priceSummary.total[0];

    ga4.gtag('event', 'purchase', {
        transaction_id: journey.token,
        value: amount,
        currency,
        tax: 0,
        shipping: 0,
        items: journey.routes.flatMap(route =>
            route.getSelectedSearchResults().map(result => ({
                item_id: result.bus.id,
                item_name: result.bus.company.companyName,
                price: result.price.amount,
                quantity: 1,
            })),
        ),
    });
};

export const trackRegisteredCustomerGroup = (customerGroupId: number, t: TFunction) =>
    ga4.event('registered-customer-group', {
        event_category: getCustomerGroupTypeTranslation({ customerGroupId, t }),
    });

export const trackJourney = (journey: IJourney) => {
    const routesData = journey.routes.flatMap((route, i) => {
        const fromStop = route.stops[0];
        const toStop = route.stops.at(-1);

        const fromLocation = fromStop?.location?.address;
        const fromDateTime = fromStop?.departureDateTime?.toFormat('dd.MM.yyyy HH:mm');
        const toLocation = toStop?.location?.address;

        return [
            { key: `route_${i}_from_location`, value: fromLocation },
            { key: `route_${i}_from_datetime`, value: fromDateTime },
            { key: `route_${i}_to_location`, value: toLocation },
        ];
    });

    routesData.forEach(({ key, value }) => {
        if (value) {
            ga4.event(key, {
                event_category: value,
            });
        }
    });
};
