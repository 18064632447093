import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useIntegrationTranslation } from '@/core/integration-translation/mod';
import { useLocalizedFormatters } from '@/core/localization/mod';
import { type DetailsBooking } from '@/entity/booking/DetailsBooking';
import { BookingTask } from '@/entity/events/task/BookingTask';
import { type BookingSearchResult } from '@/entity/search-results/BookingSearchResult';

interface ReversalLongProps {
    readonly resultOrTask?: BookingSearchResult | BookingTask;
    readonly booking: DetailsBooking | null;
    readonly showDifferentCancelPolicies?: boolean;
}

export const ReversalLong: React.FC<ReversalLongProps> = ({ resultOrTask, booking, showDifferentCancelPolicies }) => {
    const { t } = useTranslation();
    const { formatDateTime } = useLocalizedFormatters();
    const { bookingModeTranslationKey } = useIntegrationTranslation();

    const bookingMode = booking?.getBookingMode() || bookingModeTranslationKey;

    if (showDifferentCancelPolicies) {
        return <Typography variant="body2">{t(`summary.reversal_info.${bookingMode}.different_policies`)}</Typography>;
    }

    if (!resultOrTask) return null;

    const { freeCancellationDate, cancellationFreeNow } = resultOrTask;
    const formattedDate = formatDateTime(freeCancellationDate, DateTime.DATE_SHORT);

    const noRefund =
        resultOrTask instanceof BookingTask
            ? resultOrTask.priceOption?.noRefund
            : resultOrTask.selectedPriceOption?.noRefund;

    if (noRefund) {
        return (
            <Typography variant="body2" color="error.main">
                {t('summary.reversal_info.quit')}
            </Typography>
        );
    }

    if (freeCancellationDate?.isValid) {
        if (cancellationFreeNow) {
            return <UntilCancellationText bookingMode={bookingMode} formattedDate={formattedDate} booking={booking} />;
        }

        return <Typography variant="body2">{t(`summary.reversal_info.${bookingMode}.too_late`)}</Typography>;
    }

    return <Typography variant="body2">{t(`summary.reversal_info.${bookingMode}.none`)}</Typography>;
};

interface UntilCancellationTextProps {
    readonly bookingMode: string;
    readonly formattedDate: string;
    readonly booking?: DetailsBooking | null;
}

const UntilCancellationText: React.FC<UntilCancellationTextProps> = ({ bookingMode, formattedDate, booking }) => {
    const { t } = useTranslation();

    if (booking)
        return (
            <Typography variant="body2" color="success.main">
                {t(`summary.reversal_info.${bookingMode}.until_after_booking`, {
                    interpolation: { escapeValue: false },
                    formattedDate,
                })}
            </Typography>
        );

    return (
        <Typography variant="body2" color="success.main">
            {t(`summary.reversal_info.${bookingMode}.until`, {
                interpolation: { escapeValue: false },
                formattedDate,
            })}
        </Typography>
    );
};
