import React from 'react';

import { AppSettings } from '@/core/app-ctx/AppSettings';
import { type LocalizationOverrides } from '@/core/localization/mod';
import { ThemeOverrides } from '@/core/theme/ThemeOverrides';
import { type Integration } from '@/entity/integration/Integration';

export interface IAppCtxContext {
    apiVersion?: string;
    uiVersion?: string;
    themeOverrides: ThemeOverrides;
    localizationOverrides: LocalizationOverrides;
    appSettings: AppSettings;
    isIntegration: boolean;
    integration?: Integration;
    editMode: boolean;
    toggleEditMode: (state?: boolean) => void;
    loadIntegrationByName: (name?: string) => void;
}

export const AppCtxContext = React.createContext<IAppCtxContext>({
    themeOverrides: new ThemeOverrides(),
    localizationOverrides: {},
    appSettings: AppSettings.default(),
    editMode: false,
    isIntegration: false,
    toggleEditMode() {},
    loadIntegrationByName() {},
});

export function useAppCtx(): IAppCtxContext {
    const context = React.useContext(AppCtxContext);

    if (!context) throw new Error('useAppCtx must be used within a AppCtxProvider');

    return context;
}
