import { DistanceAndDuration } from '@/entity/basic/DistanceAndDuration';
import { Location } from '@/entity/basic/Location';
import { fromJsonArray } from '@/entity/index';
import { PriceSummary } from '@/entity/journey/PriceSummary';
import { type DefaultStop } from '@/entity/journey/saved/DefaultStop';
import { BookingStop } from '@/entity/journey/stop/BookingStop';
import { BookingSearchResult } from '@/entity/search-results/BookingSearchResult';
import { type IStop } from '@/features/journey-planning';

export class BookingRoute {
    public uuid: string;

    public pax: number;

    public calculatedTravel: DistanceAndDuration;

    public calculatedTravelWithBreaks: DistanceAndDuration;

    public stops: BookingStop[];

    public searchResults: BookingSearchResult[];

    public busSelectionCompleted: boolean;

    public sufficientPax: boolean;

    public secondDriverNeeded: boolean;

    public considerSplit: boolean;

    public group: number;

    public message: string;

    public priceSummary: PriceSummary;

    public driverRoomsNeeded: boolean;

    constructor(json: Record<string, any>) {
        this.uuid = json.uuid;
        this.pax = json.pax;
        this.calculatedTravel = DistanceAndDuration.fromJson(json.calculatedTravel);
        this.calculatedTravelWithBreaks = DistanceAndDuration.fromJson(json.calculatedTravelWithBreaks);
        this.stops = fromJsonArray(BookingStop, json.stops);
        this.searchResults = fromJsonArray(BookingSearchResult, json.searchResults);
        this.busSelectionCompleted = json.busSelectionCompleted;
        this.sufficientPax = json.sufficientPax;
        this.secondDriverNeeded = json.secondDriverNeeded;
        this.considerSplit = json.considerSplit;
        this.group = json.group;
        this.message = json.message;
        this.priceSummary = new PriceSummary(json.priceSummary);
        this.driverRoomsNeeded = json.driverRoomsNeeded;
    }

    public getSelectedSearchResults(): BookingSearchResult[] {
        return this.searchResults.filter(result => result.selected);
    }

    public getTotalSelectedPax(): number {
        return this.getSelectedSearchResults().reduce((sum, current) => sum + current.seatsAvailable, 0);
    }

    public getRemainingPax(): number {
        return this.pax - this.getTotalSelectedPax();
    }

    public isPossible(): boolean {
        const exceedsMaxOperationTime = this.stops.some(stop => stop.exceedsMaxOperatingTime());

        return !exceedsMaxOperationTime;
    }
}

export const getRouteTitle = (stops: BookingStop[] | DefaultStop[] | IStop[] = []): string => {
    // No stops
    if (stops.length === 0) return '';

    const first = stops[0].location;

    if (!first) return '';

    // Transform first location to Location class to get access to the helper functions
    const firstLocation = Location.fromJson(first as Record<string, any>);

    // One stop
    if (stops.length === 1) return firstLocation.city ?? firstLocation.address;

    let firstIsLast = false;

    let last = stops[stops.length - 1].location;

    if (!last) return '';

    // Multiple stops; First and Last are equal
    if (stops.length > 2 && firstLocation.equals(last)) {
        firstIsLast = true;
        last = stops[stops.length - 2].location!;
    }

    const parts = firstLocation.getCityOrState(last);

    if (firstIsLast) parts.push(parts[0]);

    return parts.join(' - ');
};
