import { type FC, type PropsWithChildren } from 'react';

import { NavButton } from '@/components/elements/nav/nav-button';
import { type NavigationItem } from '@/components/page/navigation/useNavigation';

type NavItemProps = NavigationItem;

export const NavItem: FC<PropsWithChildren<NavItemProps>> = ({ navigate, disabled, icon, children }) => {
    return (
        <NavButton disableRipple navigate={navigate} disabled={disabled} startIcon={icon} sx={{ minWidth: 0 }}>
            {children}
        </NavButton>
    );
};
