import { type Basic } from '@/entity/basic/I_Basic';

// t('account.core_data.gender.FEMALE')
// t('account.core_data.gender.MALE')
// t('account.core_data.gender.OTHER')

export enum Gender {
    FEMALE = 'FEMALE',
    MALE = 'MALE',
    OTHER = 'OTHER',
}

export class ContactData implements Basic<ContactData> {
    public static fromJson(json: Record<string, any>): ContactData {
        return new ContactData(
            json.gender,
            json.firstName,
            json.lastName,
            json.fullName,
            json.street,
            json.postCode,
            json.city,
            json.country,
            json.phone,
            json.email,
            json.homepage,
            json.address,
            json.postCodeAndCity,
        );
    }

    public readonly gender: Gender;

    public readonly firstName: string;

    public readonly lastName: string;

    public readonly fullName: string;

    public readonly address: string;

    public readonly street: string;

    public readonly postCode: string;

    public readonly city: string;

    public readonly country: string;

    public readonly phone: string;

    public readonly email: string;

    public readonly homepage: string;

    public readonly postCodeAndCity?: string;

    public organizationPoNumber?: string;

    public organizationCostCenter?: string;

    constructor(
        gender: Gender,
        firstName: string,
        lastName: string,
        fullName: string,
        street: string,
        postCode: string,
        city: string,
        country: string,
        phone: string,
        email: string,
        homepage: string,
        address: string,
        postCodeAndCity: string,
    ) {
        this.gender = gender;
        this.firstName = firstName;
        this.lastName = lastName;
        this.fullName = fullName;
        this.street = street;
        this.postCode = postCode;
        this.city = city;
        this.country = country;
        this.phone = phone;
        this.email = email;
        this.homepage = homepage;
        this.address = address;
        this.postCodeAndCity = postCodeAndCity;
    }

    public equals(other: ContactData): boolean {
        return (
            other != null &&
            this.gender === other.gender &&
            this.firstName === other.firstName &&
            this.lastName === other.lastName &&
            this.email === other.email &&
            this.phone === other.phone
        );
    }

    public toString(): string {
        return this.fullName;
    }
}
