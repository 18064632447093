import { DateTime } from 'luxon';

import { DistanceAndDuration } from '@/entity/basic/DistanceAndDuration';
import { Gateway } from '@/entity/basic/Gateway';
import { Location } from '@/entity/basic/Location';
import { Money } from '@/entity/basic/Money';
import { BookingBus } from '@/entity/bus/BookingBus';
import { PaymentOption } from '@/entity/common-constants';
import { PriceDisplayRow } from '@/entity/events/task/PriceDisplayRow';
import { PriceOption } from '@/entity/events/task/PriceOption';
import { PriceOptionDisplayRow } from '@/entity/events/task/PriceOptionDisplayRow';
import { fromJsonArray, fromJsonArrayWith } from '@/entity/index';
import { type PaymentExplanation } from '@/entity/search-results/PaymentExplanation';

export class BookingSearchResult {
    public bus: BookingBus;

    public seatsAvailable: number;

    public fromLocation: Location;

    public calculatedArrival: DistanceAndDuration;

    public calculatedReturn: DistanceAndDuration;

    public price: Money;

    public priceDisplayRows: PriceDisplayRow[];

    public priceOptionDisplayRows: PriceOptionDisplayRow[];

    public selectedPriceOption?: PriceOption;

    public minPriceOptionPrice?: Money;

    public maxPriceOptionPrice?: Money;

    public cancellationFreeNow: boolean;

    public freeCancellationDate: DateTime;

    public message?: string;

    public selected: boolean;

    public status: BusRouteStatus;

    public paymentGateways: Gateway[];

    public readonly paymentExplanation: PaymentExplanation;

    public readonly priceOptions: PriceOption[];

    public readonly ratioTransferNumber?: string;

    public readonly isEmileWeberTask: boolean;

    constructor(json: Record<string, any>) {
        this.bus = new BookingBus(json.bus);
        this.seatsAvailable = json.seatsAvailable;
        this.fromLocation = Location.fromJson(json.fromLocation);
        this.calculatedArrival = DistanceAndDuration.fromJson(json.calculatedArrival);
        this.calculatedReturn = DistanceAndDuration.fromJson(json.calculatedReturn);
        this.price = Money.fromJson(json.price);
        this.minPriceOptionPrice = json.minPriceOptionPrice ? Money.fromJson(json.minPriceOptionPrice) : undefined;
        this.maxPriceOptionPrice = json.maxPriceOptionPrice ? Money.fromJson(json.maxPriceOptionPrice) : undefined;
        this.selectedPriceOption = json.selectedPriceOption ? new PriceOption(json.selectedPriceOption) : undefined;
        this.cancellationFreeNow = json.cancellationFreeNow;
        this.freeCancellationDate = DateTime.fromISO(json.freeCancellationDate);
        this.message = json.message;
        this.priceOptions = fromJsonArray(PriceOption, json.priceOptions);
        this.selected = json.selected;
        this.status = json.status;
        this.paymentGateways = fromJsonArrayWith(Gateway.fromJson, json.paymentGateways);
        this.paymentExplanation = json.paymentExplanation;
        this.priceDisplayRows = fromJsonArray(PriceDisplayRow, json.priceDisplayRows);
        this.priceOptionDisplayRows = fromJsonArray(PriceOptionDisplayRow, json.priceOptionDisplayRows);
        this.ratioTransferNumber = json.ratioTransferNumber;
        this.isEmileWeberTask = json.emileWeberTask;
    }

    public hasInvalidStatus(): boolean {
        return this.status !== BusRouteStatus.OK;
    }

    public isOnlinePaymentPossible(): boolean {
        return this.priceOptions.some(priceOption => priceOption.paymentOption === PaymentOption.ONLINE);
    }

    public isPaymentOnInvoicePossible(): boolean {
        return this.priceOptions.some(priceOption => priceOption.paymentOption === PaymentOption.INVOICE);
    }

    public getLowestPriceOptionDisplayRows() {
        if (this.minPriceOptionPrice == null) return undefined;
        return this.priceOptionDisplayRows.find(row =>
            row.priceDisplayRows.find(priceRow => priceRow.price.amount === this.minPriceOptionPrice!.amount),
        )?.priceDisplayRows;
    }

    /**
     * Buses without a price option (no minPriceOptionPrice) cannot be selected / booked,
     * but we can still show the price without price options.
     */
    public getPriceToShow(): Money {
        return this.minPriceOptionPrice ?? this.price;
    }
}

export enum BusRouteStatus {
    OK = 'OK', // Everything is fine
    NO_PRICE_OPTIONS = 'NO_PRICE_OPTIONS', // Bus has no valid price options anymore
    INVALID_PRICE_OPTION = 'INVALID_PRICE_OPTION', // Selected payment option is not valid anymore
    FOREIGN_COMPANY = 'FOREIGN_COMPANY', // Operator selected bus from another company
    NOT_AVAILABLE = 'NOT_AVAILABLE', // Bus is no longer available (e.g. booked by another user)
    OVERLAPPING_ROUTES = 'OVERLAPPING_ROUTES', // Bus is already selected in another route that is too close in time
}

// t('bus_selection.status.NO_PRICE_OPTIONS')
// t('bus_selection.status.INVALID_PRICE_OPTION')
// t('bus_selection.status.FOREIGN_COMPANY')
// t('bus_selection.status.INCONSISTENT_CURRENCIES')
// t('bus_selection.status.NOT_AVAILABLE')
// t('bus_selection.status.OVERLAPPING_ROUTES')
