import React, { useMemo, useState } from 'react';

import { useIntegration } from '@/api/integration';
import { useApiVersion } from '@/api/public';
import { BackdropLoader } from '@/components/elements/loader/backdrop-loader';
import { AppSettings } from '@/core/app-ctx/AppSettings';
import { AppCtxContext, type IAppCtxContext } from '@/core/app-ctx/mod';
import { IntegrationTranslationProvider } from '@/core/integration-translation/IntegrationTranslationProvider';
import { ThemeOverrides } from '@/core/theme/ThemeOverrides';
import { useEmbedCtx } from '@/embed/mod';

const EDIT_MODE_STORAGE_KEY = 'editMode';

export const AppCtxProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { data: apiVersion } = useApiVersion();

    const { integrationKey, embedType, injectedThemeOverrides, injectedLocalizationOverrides, injectedAppSettings } =
        useEmbedCtx();

    const [doFetch, setDoFetch] = useState(embedType === 'integration');
    const [integrationName, setIntegrationName] = useState<string | null>(null);

    const { data: integration, isFetching } = useIntegration({
        // We only want to fetch an actual integration if embedType is integration or on print routes when integrationName is set
        doFetch,
        // On print routes we want to fetch the integration by name
        fetchByName: integrationName != null,
        // Provide either name or key to fetch
        integrationKey: integrationName ?? integrationKey,
    });

    // It is an integration if integration is not null
    const isIntegration = useMemo(() => integration != null, [integration]);

    // The AppSettings to be used.
    const appSettings = useMemo(
        () => injectedAppSettings ?? integration?.settings ?? AppSettings.default(),
        [injectedAppSettings, integration?.settings],
    );

    // The ThemeOverrides to be used.
    const themeOverrides = useMemo(
        () => injectedThemeOverrides ?? integration?.themeOverrides ?? new ThemeOverrides(),
        [injectedThemeOverrides, integration?.themeOverrides],
    );

    // The LocalizationOverrides to be used.
    const localizationOverrides = useMemo(
        () => injectedLocalizationOverrides ?? integration?.translationOverrides ?? {},
        [injectedLocalizationOverrides, integration?.translationOverrides],
    );

    const [editMode, setEditMode] = useState(sessionStorage.getItem(EDIT_MODE_STORAGE_KEY) === 'true');

    const value: IAppCtxContext = React.useMemo(
        () => ({
            apiVersion,
            uiVersion: import.meta.env.VITE_VERSION,
            themeOverrides,
            localizationOverrides,
            appSettings,
            integration,
            editMode,
            isIntegration,
            toggleEditMode(state) {
                const newState = state ?? !editMode;
                setEditMode(newState);
                sessionStorage.setItem(EDIT_MODE_STORAGE_KEY, newState.toString());
            },
            loadIntegrationByName(integrationName) {
                if (integrationName == null) return;
                setIntegrationName(integrationName);
                setDoFetch(true);
            },
        }),
        [apiVersion, themeOverrides, localizationOverrides, appSettings, integration, editMode, isIntegration],
    );

    // Otherwise the routes will be rendered before the integration is fetched and some of the routes need the integration settings to be fetched first (ex. /registration)
    if (isFetching) return <BackdropLoader open />;

    return (
        <AppCtxContext.Provider value={value}>
            <IntegrationTranslationProvider>{children}</IntegrationTranslationProvider>
        </AppCtxContext.Provider>
    );
};
